/**
 * Attaches the events on the delete direction step buttons on create a recipe.
 */
export default class DeleteDirectionButtons {
    static init() {
        const btns = document.getElementsByClassName('delete_direction_btn');

        if (btns) {
            for (const btn of btns) {
                new DeleteDirectionButtons(btn);   
            }
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} btn 
     * The button that is the delete direction step on create recipe page.
     */
    constructor(btn) {
        this.btn = btn;
        this.AttachListener();
    }

    /**
     * attaches the click of the direction step
     */
    AttachListener() {
        this.btn.addEventListener('click', async (event) => {
            // We have to call these to stop the click from propogating down to the link whilw on top of a recipe card.
            event.stopPropagation();
            event.preventDefault();

            // This traverses through the DOM until the parent row is found.
            const deletingRow = event.target.closest('.direction_item_row');

            // Now we remove the ingredient.
            deletingRow.remove();

            // Now we have to reset the name attribute in the correct order.
            const steps = document.getElementsByClassName('direction__item');
            let index = 0;
            for (const step of steps) {
                step.setAttribute('name', `directions[${index}]`);
                const stepHeader = step.parentElement.parentElement.getElementsByClassName('step')[0];
                stepHeader.innerHTML = index + 1;
                index++;
            }
        });
    }
}