//import Parsley from 'parsleyjs';
import $ from 'jquery';
import 'parsleyjs'

//const { log, info, debug, warn, error, write } = require("firebase-functions/logger");

/**
 * Adds Parsley JS to any form
 */
export default class AddParsley {
    static init() {
        // Gets all parsley forms on the page.
        const parsleyForms = document.getElementsByClassName('addParsley');
        
        // Makes sure we have some before we attach some.
        if (parsleyForms && parsleyForms.length > 0) {
            // Iterates over each form in case we have more than one.
            for (const form of parsleyForms) {
                new AddParsley(form);
            }
        }
    }

    constructor(form) {
        this.form = form;
        this.attachParsley();
    }

    /**
     * Adds the quill editor to the input.
     */
    attachParsley() {
        //new Parsley(`#${this.form.id}`);
        $(`#${this.form.id}`).parsley();
    }
}
