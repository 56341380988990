import Sortable from 'sortablejs';

/**
 * Reorganizes the ingredients.
 */
export default class ReorderIngredients {
    static init() {
        const list = document.getElementById('IngredientsList');

        if (list) {
            new ReorderIngredients(list);
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} list
     * The list of items we want to be sortable.
     */
    constructor(list) {
        this.list = list;
        this.ConfigureReordering();
    }

    /**
     * attaches the reorder handler.
     */
    ConfigureReordering() {
        // Creates a new sortable list the user can reorder using the button as the handler so the whoel row isn't it.
        Sortable.create(this.list, {
            handle: '.reorder_btn',
            animation: 150,
            onEnd: (event) => {
                // Gets the ingredients item
                const items = this.list.getElementsByClassName('ingredient__item');
                // Default index to reorder the name attribute so we can input them in order.
                let index = 0
                for (const item of items) {
                    // Reassigns the name attribute so they aren't uploaded out of order.
                    item.setAttribute('name', `ingredients[${index}]`);
                    index++;
                }
            }
        });
    }
}