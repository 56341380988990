/**
 * Hides and shows the cancel modal on the create recipe page.
 */
export default class HideShowCreateRecipeModal {
    static init() {
        const mainCancelBtn = document.getElementById('CreateRecipeCancelBtn');
        const modal = document.getElementById('createRecipeCancelModal');
        const modalCancelBtn = document.getElementById('CreateCancelModalBtn');
        const modalStayBtn = document.getElementById('CreateRecipeStayBtn');

        if (mainCancelBtn && modal && modalCancelBtn && modalStayBtn) {
            new HideShowCreateRecipeModal(mainCancelBtn, modal, modalCancelBtn, modalStayBtn);
        }
    }

    /**
     * Constructs the components.
     * @param {Element} mainCancelBtn
     * The outer cancel button on the main page.
     * @param {Element} modalCancelBtn
     * The modal as a whole on the page.
     * @param {Element} modalCancelBtn
     * Cancel button on the modal
     * @param {Element} modalStayBtn
     * Stay button on the modal.
     */
    constructor(mainCancelBtn, modal, modalCancelBtn, modalStayBtn) {
        this.mainCancelBtn = mainCancelBtn;
        this.modal = modal;
        this.modalCancelBtn = modalCancelBtn;
        this.modalStayBtn = modalStayBtn;
        this.ConfigureCancelBtn();
        this.CongigureModalStayBtn();
        this.ConfigureModalCancelBtn();
    }

    /**
     * Configures the cancel button to go back in history.
     */
    ConfigureCancelBtn() {
        this.mainCancelBtn.addEventListener('click', () => {
            this.modal.classList.add('createRecipeCancelModal--show');
        });
    }

    /**
     * Configures the stay button to just hide the modal.
     */
    CongigureModalStayBtn() {
        this.modalStayBtn.addEventListener('click', () => {
            this.modal.classList.remove('createRecipeCancelModal--show');
        });
    }

    /**
     * Configures the modal cancel button to go back in history where the user was.
     */
    ConfigureModalCancelBtn() {
        this.modalCancelBtn.addEventListener('click', () => {
            history.back();
        });
    }
}