/**
 * Attaches the events on the favorites button
 */
export default class FavoriteBtn {
    static init() {
        const btns = document.getElementsByClassName('favoriteBtn');

        if (btns) {
            for (const btn of btns) {
                new FavoriteBtn(btn);   
            }
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} btn 
     * The button that is the favorite.
     */
    constructor(btn) {
        this.btn = btn;
        this.click = this.btn.getElementsByClassName('click')[0];
        this.span = this.btn.getElementsByTagName('span')[0];
        this.info = this.btn.getElementsByClassName('info')[0];
        this.AttachListener();
    }

    /**
     * attaches the click of the favorite button.
     */
    AttachListener() {
        this.btn.addEventListener('click', async (event) => {
            // We have to call these to stop the click from propogating down to the link whilw on top of a recipe card.
            event.stopPropagation();
            event.preventDefault();

            // This means it is favorited and we want to un-favorite it.
            if (this.span.classList.contains('fa-heart')) {
                await this.removeFavoriteItem();
            }
            // This means we want to favorite this recipe.
            else {
                await this.addFavoriteItem();
            }
        });
    }

    /**
     * Adds this recipe to the users favorite.
     */
    async addFavoriteItem() {
        this.click.classList.add('active');
        this.click.classList.add('active-2');

        setTimeout(() => {
            this.span.classList.add('fa-heart')
            this.span.classList.remove('fa-heart-o')
        }, 150);

        setTimeout(() => {
            this.click.classList.add('active-3');
        }, 150);

        this.info.classList.add('info-tog');

        setTimeout(() => {
            this.info.classList.remove('info-tog');
        },1000);
    }

    /**
     * Removes this recipe as the users favorite.
     */
    async removeFavoriteItem() {
        this.click.classList.remove('active');

        setTimeout(() => {
            this.click.classList.remove('active-2');
        }, 30);

        this.click.classList.remove('active-3');
        
        setTimeout(() => {
            this.span.classList.remove('fa-heart')
            this.span.classList.add('fa-heart-o')
        }, 15);
    }
}