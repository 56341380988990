import Quill from 'quill';
//const { log, info, debug, warn, error, write } = require("firebase-functions/logger");

/**
 * Adds a rich text editor to any input.
 * 
 * NOTE: The docs are.
 * https://quilljs.com/docs/installation
 */
export default class AddQuillEditor {
    static init() {
        // Gets all editors on the page.
        const addEditors = document.getElementsByClassName('addQuillEditor');
        
        // Makes sure we have some before we attach some.
        if (addEditors && addEditors.length > 0) {
            // Iterates over each editor in case we have more than one.
            for (const addEditor of addEditors) {
                // Makes sure we have an attaching ID on the element. Quill needs it.
                if (addEditor.dataset.attachingid && addEditor.dataset.hiddeninputid) {
                    new AddQuillEditor(addEditor, addEditor.dataset.attachingid, addEditor.dataset.hiddeninputid, addEditor.dataset.placeholder);
                }
            }
        }
    }

    constructor(editor, attachingId, hiddenInputId, placeholder) {
        this.editor = editor;
        this.attachingId = attachingId;
        this.hiddenInputId = hiddenInputId;
        this.placeholder = placeholder;
        this.addEditor();
    }

    /**
     * Adds the quill editor to the input.
     */
    addEditor() {
        const quill = new Quill(`#${this.attachingId}`, {
            placeholder: this.placeholder,
            theme: 'snow'
        });

        // Sice this attaches to a div, we can't submit a div.
        // Therefore, we have to create a hidden textarea and set the inner hTML as we type so we can submit it.
        quill.on('text-change', (delta, oldDelta, source) => {
            const hiddenInput = document.getElementById(this.hiddenInputId);
            hiddenInput.innerHTML = quill.container.firstChild.innerHTML;
        });
    }
}
