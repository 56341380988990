export default class fileInput {
    static init() {
        const fileBtn = document.getElementsByClassName('file-input');
        
        if (fileBtn && fileBtn.length > 0) {
            new fileInput(fileBtn[0]);
        }
    }

    constructor(fileBtn) {
        this.fileBtn = fileBtn;
        this.AttachListener();
    }

    AttachListener() {
        this.fileBtn.addEventListener('change', () => {
            const reader = new FileReader();
        
            reader.onload = (e) => {
                const curElement = document.getElementsByClassName('image')[0];
                // get loaded data and render thumbnail.
                curElement.setAttribute('src', e.target.result);
            };
        
            // read the image file as a data URL.
            reader.readAsDataURL(this.fileBtn.files[0]);
        });
    }
}
