/**
 * Creates an insatnce of the load more recipes for the meal types.
 */
export default class LoadMoreRecipes {
    static init() {
        const scroller = document.getElementById('recipeScroller');
        const pageNumber = document.getElementById('recipePageNum');
        const mealList = document.getElementById('MealsList');
        const loader = document.getElementById('loadMore');

        if (scroller && pageNumber && mealList && loader) {
            new LoadMoreRecipes(scroller, pageNumber, mealList, loader);
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} scroller 
     * @param {Element} pageNumber 
     * @param {Element} mealList 
     * @param {Element} loader 
     */
    constructor(scroller, pageNumber, mealList, loader) {
        this.scroller = scroller;
        this.pageNumber = pageNumber;
        this.mealList = mealList;
        this.loader = loader;
        
        window.addEventListener('scroll', async (event) => {
            event.preventDefault();
            event.stopPropagation();
            await this.ConfigureScroller();
        });
    }

    /**
     * When the user scrolls to the bottom of the page this runs.
     */
    async ConfigureScroller() {
        // If we are currently loading results we don't recall this.
        if (!this.loader.classList.contains('hidden')) {
            return;
        }

        // This starts a run if we are currently not loading more already.
        // We make sure the user is on the bottom of the page.
        const endOfPage = window.innerHeight + window.scrollY >= document.body.offsetHeight;

        // If the user is on the bottom of the page then we enter and run a new load.
        if (endOfPage) {
            // First we show the loading cards.
            this.loader.classList.remove('hidden');

            // Now we fetch more records from the URL in the data attribute on the page hidden input.
            const resp = await fetch(this.pageNumber.dataset.loadmoreurl);
            if (resp.ok) {
                const body = await resp.text();
                this.mealList.innerHTML += body;

                // Now we increment the page number.
                this.pageNumber.setAttribute('value', parseInt(this.pageNumber.value) + 1);

                // Now we have to reset the next url attribute for the next page.
                const cards = document.getElementsByClassName('recipe_card');
                // Now we have to break down and reset the last doc for the async URL
                const curUrl = this.pageNumber.dataset.loadmoreurl;
                const urlSplit = curUrl.split('?');
                const params = urlSplit[1].split('&')
                const lastDocParam = params[0].split('=')[1];
                const lastDocId = cards[cards.length - 1].dataset.docid;
                // Now we reset the URL fr the next page.
                const nextUrl = curUrl.replace(lastDocParam, lastDocId);
                // Now we set the next url in the data attribute.
                this.pageNumber.setAttribute('data-loadmoreurl', nextUrl);
            }

            // Now we hide the loading cards showing we are done loading.
            this.loader.classList.add('hidden');
        }
    }
}