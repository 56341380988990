import { collection, getDocs } from "firebase/firestore"; 

export default class LandingSection {
    static init(db) {
        const getBtn = document.getElementById('getDocBtn');
        const dataLoadElement = document.getElementById('docInfo');

        if (getBtn && dataLoadElement && db) {
            new LandingSection(getBtn, dataLoadElement, db);
        }
    }

    constructor(btn, dataLoadElement, db) {
        this.btn = btn;
        this.db = db;
        this.dataLoadElement = dataLoadElement;
        this.AttachListener();
    }

    AttachListener() {
        this.btn.addEventListener('click', async () => {
            const querySnapshot = await getDocs(collection(this.db, "Clients"));
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                this.dataLoadElement.innerHTML += data.companyName;
            });
        });
    }
}