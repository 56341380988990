/**
 * Attaches the events on the delete ingredient buttons on create a recipe.
 */
export default class DeleteIngredientButtons {
    static init() {
        const btns = document.getElementsByClassName('delete_ingredient_btn');

        if (btns) {
            for (const btn of btns) {
                new DeleteIngredientButtons(btn);   
            }
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} btn 
     * The button that is the delete ingredient on create recipe page.
     */
    constructor(btn) {
        this.btn = btn;
        this.AttachListener();
    }

    /**
     * attaches the click of the favorite button.
     */
    AttachListener() {
        this.btn.addEventListener('click', async (event) => {
            // We have to call these to stop the click from propogating down to the link whilw on top of a recipe card.
            event.stopPropagation();
            event.preventDefault();

            // This traverses through the DOM until the parent row is found.
            const deletingRow = event.target.closest('.ingredients__list__row');

            // Now we remove the ingredient.
            deletingRow.remove();

            // Now we have to reset the name attribute in the correct order.
            const ingredients = document.getElementsByClassName('ingredient__item');
            let index = 0;
            for (const item of ingredients) {
                item.setAttribute('name', `ingredients[${index}]`);
                index++;
            }
        });
    }
}