/**
 * Creates a new star rating component.
 */
export default class RecipeRater {
    static init() {
        const rater = document.getElementsByClassName('rater');

        if (rater) {
            new RecipeRater(rater);
        }
    }

    /**
     * Creates a new instance of class.
     * @param {Element} rater 
     * The element we want to attach the rater to.
     * <div class="rater" data-average="{{this.ratingAvg}}" data-isDisabled="true"></div>
     */
    constructor(rater) {
        this.rater = rater;
        this.ConfigureRating();
    }

    /**
     * Sets the average rating
     */
    ConfigureRating() {
    }
}