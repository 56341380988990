import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 
import { getAnalytics } from "firebase/analytics";
import LandingSection from './landing/landing_section';
import RecipeRater from './recipes/recipe_rater';
import FavoriteBtn from './recipes/favorite_btn';
import recipeCancelModal from './recipes/hide_show_recipe_cancel_modal';
import FileInput from './components/inputs/file_input';
import AddQuillEditor from './components/inputs/add_quill_editor';
import AddIngredientsBtns from './recipes/add_ingredients_btns';
import AddDirectionsBtns from './recipes/add_directions_btns';
import ReorderIngredients from './recipes/reorder_ingredients';
import ReorderDirections from './recipes/reorder_directions';
import DeleteIngredientButtons from './recipes/delete_ingredient_btn';
import DeleteDirectionButtons from './recipes/delete_direction_btn';
import LoadMoreRecipes from './recipes/load_more_recipes';
import AddParsley from './validation/add_parsley';

/* Initialize all js components. */
const initAll = async () => {
  const firebaseConfig = {
    apiKey: "AIzaSyDN1k3O9eu-MoQgut3IiK5454ZXowWBVyc",
    authDomain: "lmg-technologies.firebaseapp.com",
    databaseURL: "https://lmg-technologies-default-rtdb.firebaseio.com",
    projectId: "lmg-technologies",
    storageBucket: "lmg-technologies.appspot.com",
    messagingSenderId: "815051765304",
    appId: "1:815051765304:web:08a6aea50640e0fb65d8cd",
    measurementId: "G-8MDBDMHYBP"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const db = getFirestore(app);
  LandingSection.init(db);
  RecipeRater.init();
  FavoriteBtn.init();
  FileInput.init();
  recipeCancelModal.init();
  AddQuillEditor.init();
  AddIngredientsBtns.init();
  AddDirectionsBtns.init();
  ReorderIngredients.init();
  ReorderDirections.init();
  DeleteIngredientButtons.init();
  DeleteDirectionButtons.init();
  LoadMoreRecipes.init();
  AddParsley.init();
}

/** Executed immediately as this is an entry script. **/
initAll();
